import React, { useEffect, useState } from 'react'
import FooterBase from '../../../../molecules/FooterBase'
import { Divider, HStack, Container, SimpleGrid, Flex, Box, VStack, Heading } from '@chakra-ui/react'
import GiftVideoWrapper from '../../../Published/Theatre/GiftVideoWrapper2'
import { useGiftInstance } from '../../../Published/Theatre'
import { useEventContext } from '../../../../../contexts/EventProvider'
import CopyLink from '../../../../molecules/CopyLink'
import InvitationMethods from '../../../../molecules/InvitationMethods'
import ThankYouSelector from '../../../../molecules/ThankYouSelector'
import GiftCardSelector from '../selectors/GiftCardSelector'
import { useSelector, shallowEqual } from 'react-redux'
import PublicCallout from '../../atoms/PublicCallout'
import { useRoleContext } from '../../../../../contexts/RoleProvider'
import NavBar from '../../../../atoms/NavBar'
import ExitButton from '../../../../atoms/nav-controls/ExitButton'
import { useNavigate } from 'react-router-dom'
import { useEventHandlersV2 } from '../../../../../hooks/useEventHandlers'
import { DownloadVideoSelector } from '../../../Download/selectors/DownloadVideoSelector'
import TheatreHeader from '../../../../molecules/TheatreHeader'
import { VisibilitySelector } from '../selectors/VisibilitySelector'
import SharingOptionsCard from '../../../../molecules/SharingOptionsCard'
import KeepsakeCards from '../../../Keepsake/atoms/KeepsakeCards'
import Socials from '../../../../molecules/SocialButtons'
import redirectToNextIfWindow from '../../../../../utils/redirectToNextIfWindow'

export const PresentationNav = ({ event }) => {
	const navigate = useNavigate()
	const handlers = useEventHandlersV2(event.uuid)

	const handleExit = () => {
		navigate(handlers.root)
	}
	const handleSend = () => {
		redirectToNextIfWindow(handlers.share_recipient)
		// navigate(handlers.share_recipient)
	}

	return (
		<NavBar>
			<HStack spacing={['4%', '1rem']}>
				<ExitButton left text="Leave" onClick={handleExit} />
			</HStack>
		</NavBar>
	)
}

const PublicOrRecipient = () => {
	const { role } = useRoleContext()
	const { event, isOwner } = useEventContext()
	const giftInstance = useGiftInstance(event)
	const showConfetti = event.occasion !== 'memorial' && event.giftWrapColor !== 'none'

	// console.log(event, 'event public')
	const funders = useSelector((s) => s.event.funders, shallowEqual)
	const video = useSelector((s) => s.event.thankYouVideo, shallowEqual)

	const showGiftCard = role != 'public' && funders && funders.length > 0
	const showDownload = role == 'recipient' || (event.allowDownload && !event.isPrivate)
	const showKeepsakes = role == 'recipient' || (event.allowKeepsakes && !event.isPrivate)
	const showThankYou = role == 'recipient' || role == 'creator' || video
	const hasAccess = role == 'recipient' || role == 'creator'

	/**
	 * Calculate how many columns we need to display.
	 */
	let cols = 0
	if (showKeepsakes || showDownload) {
		cols += 1
	}
	if (showThankYou) {
		cols += 1
	}
	if (hasAccess) {
		cols += 1
	}

	const cardsLayout = [1, cols]

	return (
		<>
			{isOwner && <PresentationNav event={event} />}

			<Box mb="1rem">
				<TheatreHeader />
			</Box>

			<Container maxW="container.lg" pt="2rem" pb="1rem" variant="main" px="1rem">
				<GiftVideoWrapper role={role} giftInstance={giftInstance} showConfetti={showConfetti} />

				{showGiftCard && <GiftCardSelector />}

				<SimpleGrid columns={cardsLayout} spacing="1rem" my="1rem">
					<ThankYouSelector video={video} role={role} event={event} />
					{(showDownload || showKeepsakes) && (
						<DownloadVideoSelector isPresentation={true} variant="outline" />
					)}
					{hasAccess && <VisibilitySelector event={event} role={role} mb={['2rem', '']} overflow="visible" />}
				</SimpleGrid>

				{hasAccess && !event.isPrivate && (
					<SharingOptionsCard
						hideHeader
						background="transparent"
						border="1px solid var(--vidday-colors-whiteAlpha-300)"
						mb="1rem"
						role="recipient"
						variant="outline"
					/>
				)}

				{role == 'public' && !event.isPrivate && (
					<>
						<CopyLink
							event={event}
							label="Share video with people:"
							labelColor="white"
							linkColor="white"
							mb="1rem"
						/>

						<Flex maxW={['100%', '90%']} position="relative" w="full" mx="auto">
							<InvitationMethods theme="dark" shareOption={role == 'recipient' ? 'fromRecipient' : ''} />
						</Flex>
					</>
				)}

				<VStack spacing="1rem">
					{role != 'recipient' && !event.isPrivate && event.allowKeepsakes && (
						<Box mb="2rem" textAlign="center">
							<Divider variant="dashed" my="2rem" borderColor="#314659" />
							<Heading size="lg" mb="2rem">
								Order a Keepsake
							</Heading>
							<KeepsakeCards />
						</Box>
					)}
					{role == 'public' && (
						<>
							<Divider variant="dashed" my="1rem" borderColor="#314659" />
							<PublicCallout />
						</>
					)}
				</VStack>
			</Container>

			<Socials />

			<FooterBase />
		</>
	)
}

export default PublicOrRecipient
